import './home.css';

export default function home() {
    return (
        <>
            <div class="search-header-offset"></div>
            <header class="home-header">
                <h1>MoosicBox</h1>
            </header>
        </>
    );
}
